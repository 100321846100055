import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const breakpoints = useBreakpoints(breakpointsTailwind);

export const isMobile = breakpoints.smaller("md");
export const isNotMobile = breakpoints.greaterOrEqual("md");
export const isMobileOrTablet = breakpoints.smaller("lg");
export const isTablet = breakpoints.between("md", "lg");
export const isLaptop = breakpoints.between("lg", "xl");
export const isLaptopOrDesktop = breakpoints.greaterOrEqual("lg");
export const isDesktop = breakpoints.greaterOrEqual("xl");
